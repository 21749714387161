<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.sponsorsByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="sponsorsHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="sponsors"
            :search="searchString"
            class="elevation-1"
            multi-sort>
            <template v-slot:item.description="{ item }">
              <div class="description"
                v-html="
                  item.description.length > 100 ? item.description.substring(0, 100) + '...' : item.description
                "></div>
            </template>
            <template v-slot:item.move_up="{ item, index }">
              <v-btn icon @click="moveItem(index, 'up')" :disabled="index === 0">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.move_down="{ item, index }">
              <v-btn icon @click="moveItem(index, 'down')" :disabled="index === sponsors?.length - 1">
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.sponsors') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer></v-spacer>

                <v-dialog v-model="dialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.newSponsor') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(formTitle) }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="close"></v-icon>
                    </v-card-title>

                    <v-tabs v-model="activeTab">
                      <v-tab key="me" @click.prevent="selectActiveTab(0)">{{ $t('languages.me') }}</v-tab>
                      <v-tab key="en" @click.prevent="selectActiveTab(1)">{{ $t('languages.en') }}</v-tab>
                      <v-tab key="ru" @click.prevent="selectActiveTab(2)">{{ $t('languages.ru') }}</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="activeTab">
                      <v-tab-item key="me">
                        <validation-observer ref="formMe" v-slot="{ handleSubmit, failed }">
                          <v-form lazy-validation @submit.prevent="handleSubmit(selectActiveTab)">
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" hidden md="6" sm="12">
                                    <v-text-field v-model="editedItem.id" :label="$t('sponsorsPage.id')"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider rules="required|max:200" v-slot="{ errors }" name="name">
                                      <v-text-field
                                        v-model="editedItem.title"
                                        name="name"
                                        clearable
                                        counter="200"
                                        :label="$t('sponsorsPage.name')"
                                        @focusout="translateTitle(editedItem.title)"
                                        :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                  </v-col>

                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider rules="required|url|max:200" v-slot="{ errors }" name="link">
                                      <v-text-field
                                        v-model="editedItem.link"
                                        name="link"
                                        clearable
                                        counter="200"
                                        :label="$t('sponsorsPage.link')"
                                        :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider rules="required" v-slot="{ errors }" name="type">
                                      <v-autocomplete
                                        v-model="editedItem.type"
                                        name="type"
                                        :items="types"
                                        item-text="name"
                                        item-value="id"
                                        :label="$t('sponsorsPage.type')"
                                        :error-messages="errors"
                                        :no-data-text="$t('select.noDataAvailable')"
                                        @focus="$event.target.click()"></v-autocomplete>
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider
                                      :rules="
                                        editedItem.logo_picture_path ? 'image|size:4000' : 'image|required|size:4000'
                                      "
                                      v-slot="{ errors }"
                                      name="logo">
                                      <v-file-input
                                        v-model="editedItem.logo"
                                        accept="image/jpg, image/jpeg, image/png"
                                        counter
                                        :label="$t('sponsorsPage.logo') + '(600x240)'"
                                        prepend-icon="mdi-camera"
                                        show-size
                                        small-chips
                                        :error-messages="errors"></v-file-input>
                                    </validation-provider>
                                  </v-col>

                                  <v-col cols="12" md="12" sm="12">
                                    <v-label>{{ $t('sponsorsPage.description') }}</v-label>
                                    <validation-provider rules="required" v-slot="errors" name="description">
                                      <vue-editor
                                        v-model="editedItem.description"
                                        :placeholder="$t('sponsorsPage.description')"
                                        @blur="translateDescription(editedItem.description)"></vue-editor>
                                      <div v-if="errors.errors[0]" v class="v-text-field__details">
                                        <div class="v-messages theme--light error--text" role="alert">
                                          <div class="v-messages__wrapper">
                                            <div class="v-messages__message">
                                              {{ errors.errors[0] }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </validation-provider>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <button-submit
                                :failed="failed"
                                :loading="loading"
                                buttonText="buttons.next"></button-submit>
                            </v-card-actions>
                          </v-form>
                        </validation-observer>
                      </v-tab-item>

                      <v-tab-item key="en">
                        <validation-observer ref="formEn" v-slot="{ handleSubmit, failed }">
                          <v-form lazy-validation @submit.prevent="handleSubmit(selectActiveTab)">
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" hidden md="6" sm="12">
                                    <v-text-field v-model="editedItem.id" :label="$t('sponsorsPage.id')"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider rules="required|max:200" v-slot="{ errors }" name="name">
                                      <v-text-field
                                        v-model="editedItem.title_en"
                                        name="name"
                                        clearable
                                        counter="200"
                                        :label="$t('sponsorsPage.name')"
                                        :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                  </v-col>

                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider rules="required|url|max:200" v-slot="{ errors }" name="link">
                                      <v-text-field
                                        v-model="editedItem.link"
                                        name="link"
                                        clearable
                                        counter="200"
                                        :label="$t('sponsorsPage.link')"
                                        :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider rules="required" v-slot="{ errors }" name="type">
                                      <v-autocomplete
                                        v-model="editedItem.type"
                                        name="type"
                                        :items="types"
                                        item-text="name"
                                        item-value="id"
                                        :label="$t('sponsorsPage.type')"
                                        :error-messages="errors"
                                        :no-data-text="$t('select.noDataAvailable')"
                                        @focus="$event.target.click()"></v-autocomplete>
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider
                                      :rules="
                                        editedItem.logo_picture_path ? 'image|size:4000' : 'image|required|size:4000'
                                      "
                                      v-slot="{ errors }"
                                      name="logo">
                                      <v-file-input
                                        v-model="editedItem.logo"
                                        accept="image/jpg, image/jpeg, image/png"
                                        counter
                                        :label="$t('sponsorsPage.logo') + '(600x240)'"
                                        prepend-icon="mdi-camera"
                                        show-size
                                        small-chips
                                        :error-messages="errors"></v-file-input>
                                    </validation-provider>
                                  </v-col>

                                  <v-col cols="12" md="12" sm="12">
                                    <v-label>{{ $t('sponsorsPage.description') }}</v-label>
                                    <validation-provider rules="required" v-slot="errors" name="description">
                                      <vue-editor
                                        v-model="editedItem.description_en"
                                        :placeholder="$t('sponsorsPage.description')"
                                        @blur="translateDescription(editedItem.description_en)"></vue-editor>
                                      <div v-if="errors.errors[0]" v class="v-text-field__details">
                                        <div class="v-messages theme--light error--text" role="alert">
                                          <div class="v-messages__wrapper">
                                            <div class="v-messages__message">
                                              {{ errors.errors[0] }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </validation-provider>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <button-submit
                                :failed="failed"
                                :loading="loading"
                                buttonText="buttons.next"></button-submit>
                            </v-card-actions>
                          </v-form>
                        </validation-observer>
                      </v-tab-item>

                      <v-tab-item key="ru">
                        <validation-observer ref="formRu" v-slot="{ handleSubmit, failed }">
                          <v-form lazy-validation @submit.prevent="handleSubmit(selectActiveTab)">
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" hidden md="6" sm="12">
                                    <v-text-field v-model="editedItem.id" :label="$t('sponsorsPage.id')"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider rules="required|max:200" v-slot="{ errors }" name="name">
                                      <v-text-field
                                        v-model="editedItem.title_ru"
                                        name="name"
                                        clearable
                                        counter="200"
                                        :label="$t('sponsorsPage.name')"
                                        :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                  </v-col>

                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider rules="required|url|max:200" v-slot="{ errors }" name="link">
                                      <v-text-field
                                        v-model="editedItem.link"
                                        name="link"
                                        clearable
                                        counter="200"
                                        :label="$t('sponsorsPage.link')"
                                        :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider rules="required" v-slot="{ errors }" name="type">
                                      <v-autocomplete
                                        v-model="editedItem.type"
                                        name="type"
                                        :items="types"
                                        item-text="name"
                                        item-value="id"
                                        :label="$t('sponsorsPage.type')"
                                        :error-messages="errors"
                                        :no-data-text="$t('select.noDataAvailable')"
                                        @focus="$event.target.click()"></v-autocomplete>
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider
                                      :rules="
                                        editedItem.logo_picture_path ? 'image|size:4000' : 'image|required|size:4000'
                                      "
                                      v-slot="{ errors }"
                                      name="logo">
                                      <v-file-input
                                        v-model="editedItem.logo"
                                        accept="image/jpg, image/jpeg, image/png"
                                        counter
                                        :label="$t('sponsorsPage.logo') + '(600x240)'"
                                        prepend-icon="mdi-camera"
                                        show-size
                                        small-chips
                                        :error-messages="errors"></v-file-input>
                                    </validation-provider>
                                  </v-col>

                                  <v-col cols="12" md="12" sm="12">
                                    <v-label>{{ $t('sponsorsPage.description') }}</v-label>
                                    <validation-provider rules="required" v-slot="errors" name="description">
                                      <vue-editor
                                        v-model="editedItem.description_ru"
                                        :placeholder="$t('sponsorsPage.description')"
                                        @blur="translateDescription(editedItem.description_ru)"></vue-editor>
                                      <div v-if="errors.errors[0]" v class="v-text-field__details">
                                        <div class="v-messages theme--light error--text" role="alert">
                                          <div class="v-messages__wrapper">
                                            <div class="v-messages__message">
                                              {{ errors.errors[0] }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </validation-provider>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <button-submit
                                :failed="failed"
                                :loading="loading"
                                buttonText="buttons.save"></button-submit>
                            </v-card-actions>
                          </v-form>
                        </validation-observer>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.actions') }}
                    </span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="editItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.edit') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="showProfilePicture(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-fit-to-screen</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.showImage') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.showImage') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="showSponsorModal(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.delete') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.delete') }}</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-snackbar v-model="snackbar.active" :color="snackbar.color" :timeout="2000" bottom>
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar.active = false">
            {{ $t('snackbar.close') }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>

    <v-dialog v-model="deleteSponsorModal" max-width="650">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ $t('sponsorsPage.deleteSponsor') }}
          <v-spacer></v-spacer>
          <v-icon class="mdi mdi-close" style="color: white" @click="closeDeleteSponsorModal"></v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <p>
              {{ $t('sponsorsPage.deleteMessage') }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" text @click="deleteSponsor">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogImage" height="auto" width="auto">
      <v-card>
        <v-img
          v-if="itemImage"
          :src="itemImage"
          max-height="600"
          max-width="600"
          style="background-size: cover"></v-img>
        <v-img
          v-else
          aspect-ratio="2"
          contain
          height="250px"
          src="@/assets/default-user.png"
          style="background-size: cover"
          width="250px"></v-img>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { sponsorsHeaders } from '@/mixins/data-table-headers';
import { defaultSponsor } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { formatDate } from '@/utils/formatDate';
import { VueEditor, Quill } from 'vue2-editor';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-vue';

Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);

export default {
  name: 'sponsorsPage',
  components: { ButtonSubmit, VueEditor },
  data: () => ({
    searchString: '',
    dialog: false,
    dialogDelete: false,
    sponsors: [],
    formTitle: 'sponsorsPage.newItem',
    formValid: true,
    editedItem: {},
    editedItemDeleted: {},
    enableSave: true,
    deleteSponsorModal: false,
    statusSuccessfullyChanged: false,
    loading: false,
    snackbar: {
      active: false,
      color: '',
      text: '',
    },
    types: [
      { id: 'partner', name: 'Partner' },
      { id: 'sponsor', name: 'Sponsor' },
    ],
    dialogImage: false,
    itemImage: null,
    activeTab: null,
  }),
  created() {
    this.editedItem = Object.assign({}, defaultSponsor);
    this.loadAllSponsors();
  },
  computed: {
    sponsorsHeaders() {
      return sponsorsHeaders(i18n);
    },
  },
  methods: {
    async loadAllSponsors() {
      await this.$store.dispatch('sponsors/getAllSponsors').then((res) => {
        this.sponsors = res.data.map((item) => {
          return {
            ...item,
            title: JSON.parse(item.title).me,
            title_en: JSON.parse(item.title).en,
            title_ru: JSON.parse(item.title).ru,
            description: JSON.parse(item.description).me,
            description_en: JSON.parse(item.description).en,
            description_ru: JSON.parse(item.description).ru,
            created_at: formatDate(item.created_at),
          };
        });
      });
    },

    editItem(item) {
      this.editedItem = this.sponsors.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.formTitle = item.title;
      this.dialog = true;
    },

    async deleteSponsor() {
      this.loading = true;

      await this.$store
        .dispatch('sponsors/deleteSponsor', this.editedItemDeleted)
        .then(() => {
          this.loadAllSponsors();
          this.showSnackbar(i18n.t('snackbar.deletedSuccessfully'), 'green');
          this.closeDeleteSponsorModal();
        })
        .catch(() => {
          this.showSnackbar(i18n.t('snackbar.anErrorOccured'), 'red');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async selectActiveTab(tab = null) {
      let previousTab = this.activeTab;
      const isValid = await this.validateCurrentTab();
      if (isValid) {
        if (tab !== null) {
          if (previousTab == 0) {
            this.translateDescription(this.editedItem.description);
          }
          this.activeTab = tab; // Change to the selected tab if current is valid
        } else {
          if (this.activeTab < 2) {
            if (previousTab == 0) {
              this.translateDescription(this.editedItem.description);
            }
            this.activeTab += 1; // Move to the next tab if current tab is validated
          } else {
            this.save(); // Save if the last tab is validated
          }
        }
      } else {
        this.activeTab = previousTab;
      }
    },

    async validateCurrentTab() {
      switch (this.activeTab) {
        case 0:
          return await this.$refs.formMe.validate();
        case 1:
          return await this.$refs.formEn.validate();
        case 2:
          return await this.$refs.formRu.validate();
        default:
          return false;
      }
    },

    createFormData() {
      let formData = new FormData();
      if (this.editedItem.id) {
        formData.append('_method', 'POST');
        formData.append('id', this.editedItem.id);
      } else {
        formData.append('id', '');
      }
      formData.append(
        'title',
        JSON.stringify({
          me: this.editedItem.title,
          en: this.editedItem.title_en,
          ru: this.editedItem.title_ru,
        })
      );
      formData.append(
        'description',
        JSON.stringify({
          me: this.editedItem.description,
          en: this.editedItem.description_en,
          ru: this.editedItem.description_ru,
        })
      );

      formData.append('link', this.editedItem.link);
      formData.append('type', this.editedItem.type);
      formData.append('logo', this.editedItem.logo);
      formData.append('logo', this.editedItem.logo ? this.editedItem.logo : '');
      return formData;
    },

    async save() {
      this.enableSave = false;
      this.loading = true;
      let path = 'saveSponsor';

      if (this.editedItem.id) {
        path = 'updateSponsor';
      }
      let formData = this.createFormData();

      await this.$store
        .dispatch('sponsors/' + path, formData)
        .then(() => {
          this.loadAllSponsors();
          this.showSnackbar(i18n.t('snackbar.savedSuccessfully'), 'green');

          this.close();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.showSnackbar(e, 'red'));
          } else {
            this.showSnackbar(i18n.t('snackbar.anErrorOccured'), 'red');
          }
        })
        .finally(() => {
          this.enableSave = true;
          this.loading = false;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultSponsor);
      });
      this.$refs.formMe && this.$refs.formMe.reset();
      this.$refs.formEn && this.$refs.formEn.reset();
      this.$refs.formRu && this.$refs.formRu.reset();
      this.formTitle = 'sponsorsPage.newItem';
      this.activeTab = 'me';
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultSponsor);
      });
    },

    showSnackbar(text, color) {
      this.snackbar.active = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
    },

    showSponsorModal(item) {
      this.editedItemDeleted = item;
      this.deleteSponsorModal = true;
    },

    showProfilePicture(item) {
      this.dialogImage = true;
      if (item?.logo_picture_path) {
        this.itemImage = item?.logo_picture_path;
      } else {
        this.itemImage = null;
      }
    },

    closeDeleteSponsorModal() {
      this.deleteSponsorModal = false;
    },

    moveItem(index, direction) {
      const newIndex = direction === 'up' ? index - 1 : index + 1;

      // Check if the newIndex is within the valid range
      if (newIndex >= 0 && newIndex < this.sponsors.length) {
        let newSponsors = [...this.sponsors];

        // Swapping the current item with the one in the new position
        [newSponsors[index], newSponsors[newIndex]] = [newSponsors[newIndex], newSponsors[index]];

        // Update the sponsors array to trigger reactivity
        this.sponsors = newSponsors;

        // Optional: Call a method to update order numbers or any other necessary state
        this.orderSponsors();
      }
    },

    createOrderSponsorsFormData() {
      let formData = new FormData();

      formData.append('_method', 'POST');

      this.sponsors.map((item, index) => {
        formData.append('sponsorArray[]', JSON.stringify({ id: item.id, order_number: index + 1 }));
      });

      return formData;
    },

    async orderSponsors() {
      let path = 'orderSponsors';

      let formData = this.createOrderSponsorsFormData();

      await this.$store
        .dispatch('sponsors/' + path, formData)
        .then(() => {
          this.loadAllSponsors();
          this.showSnackbar(i18n.t('snackbar.savedSuccessfully'), 'green');
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.showSnackbar(e, 'red'));
          } else {
            this.showSnackbar(i18n.t('snackbar.anErrorOccured'), 'red');
          }
        });
    },

    async translateTitle(text) {
      this.editedItem.title_en = await this.translateText(text, 'en', 'sr');
      this.editedItem.title_ru = await this.translateText(text, 'ru', 'sr');
    },

    async translateDescription(text) {
      this.editedItem.description_en = await this.translateText(text, 'en', 'sr');
      this.editedItem.description_ru = await this.translateText(text, 'ru', 'sr');
    },

    async translateText(query, targetLang, sourceLang) {
      var data = {
        q: query,
        target: targetLang,
        source: sourceLang,
      };

      try {
        const response = await this.$store.dispatch('googleTranslate/translateText', data);
        const jsonData = await response.json(); // Assuming the action returns a response compatible with `.json()`
        const translatedText = jsonData.data.translations[0].translatedText;

        return translatedText; // This will be returned to the caller of translateText
      } catch (error) {
        this.showSnackbar(i18n.t('snackbar.anErrorOccuredWithTranslation'), 'red');
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>

<style>
.stats {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.cell-style {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.description p {
  margin-bottom: 0;
}
</style>

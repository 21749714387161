<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.articlesByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="articlesHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="articles"
            :search="searchString"
            class="elevation-1"
            multi-sort>
            <template v-slot:item.title="{ item }">
              {{ JSON.parse(item.title).me }}
            </template>

            <template v-slot:item.intro_text="{ item }">
              {{ JSON.parse(item.intro_text).me }}
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.articles') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer></v-spacer>

                <v-dialog v-model="dialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.newArticle') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(formTitle) }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="close"></v-icon>
                    </v-card-title>
                    <v-tabs v-model="activeTab">
                      <v-tab key="me" @click.prevent="selectActiveTab(0)">{{ $t('languages.me') }}</v-tab>
                      <v-tab key="en" @click.prevent="selectActiveTab(1)">{{ $t('languages.en') }}</v-tab>
                      <v-tab key="ru" @click.prevent="selectActiveTab(2)">{{ $t('languages.ru') }}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="activeTab">
                      <v-tab-item key="me">
                        <validation-observer ref="formMe" v-slot="{ handleSubmit, failed }">
                          <v-form lazy-validation @submit.prevent="handleSubmit(selectActiveTab)">
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider rules="required|max:200" v-slot="{ errors }" name="title">
                                      <v-text-field
                                        v-model="editedItem.title"
                                        name="name"
                                        clearable
                                        counter="200"
                                        :label="$t('articlesPage.title')"
                                        @focusout="translateTitle(editedItem.title)"
                                        :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                  </v-col>

                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider rules="required|max:200" v-slot="{ errors }" name="intro_text">
                                      <v-text-field
                                        v-model="editedItem.intro_text"
                                        name="intro_text"
                                        clearable
                                        counter="200"
                                        :label="$t('articlesPage.intro_text')"
                                        @focusout="translateIntroText(editedItem.intro_text)"
                                        :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                  </v-col>

                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider
                                      :rules="editedItem.picture_path ? 'image|size:4000' : 'required|image|size:4000'"
                                      v-slot="{ errors }"
                                      name="image">
                                      <v-file-input
                                        v-model="editedItem.picture"
                                        accept="image/jpg, image/jpeg, image/png"
                                        counter
                                        :label="$t('articlesPage.image')"
                                        prepend-icon="mdi-camera"
                                        show-size
                                        small-chips
                                        :error-messages="errors"></v-file-input>
                                    </validation-provider>
                                  </v-col>

                                  <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-menu
                                        ref="publishDate"
                                        v-model="publishDate"
                                        :close-on-content-click="false"
                                        :return-value.sync="editedItem.publish_date"
                                        min-width="auto"
                                        offset-y
                                        transition="scale-transition">
                                      <template v-slot:activator="{ on, attrs }">
                                        <validation-provider rules="required" v-slot="{ errors }" name="pickup_time">
                                          <v-text-field
                                              v-model="editedItem.publish_date"
                                              clearable
                                              :label="$t('articlesHeaders.publishDate')"
                                              prepend-icon="mdi-calendar"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                              :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                      </template>
                                      <DatePicker
                                          v-model="editedItem.publish_date"
                                          :minute-increment="1"
                                          :model-config="modelConfig"
                                          class="no-border"
                                          is24hr
                                          mode="dateTime">
                                      </DatePicker>
                                      <div class="date-picker-buttons">
                                        <v-btn class="primary cancel mr-2" elevation="2" text @click="publishDate = false">
                                          {{ $t('buttons.cancel') }}
                                        </v-btn>
                                        <v-btn class="primary mr-2" elevation="2" text @click="updatePublishDate">
                                          {{ $t('buttons.confirm') }}
                                        </v-btn>
                                      </div>
                                    </v-menu>
                                  </v-col>

                                  <v-col cols="12" md="12" sm="12">
                                    <v-label>{{ $t('articlesPage.body') }}</v-label>
                                    <validation-provider rules="required" v-slot="errors" name="body">
                                      <vue-editor
                                        v-model="editedItem.body"
                                        :placeholder="$t('articlesPage.body')"
                                        @blur="translateDescription(editedItem.body)"></vue-editor>
                                      <div v-if="errors.errors[0]" v class="v-text-field__details">
                                        <div class="v-messages theme--light error--text" role="alert">
                                          <div class="v-messages__wrapper">
                                            <div class="v-messages__message">
                                              {{ errors.errors[0] }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </validation-provider>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <button-submit
                                :failed="failed"
                                :loading="loading"
                                buttonText="buttons.next"></button-submit>
                            </v-card-actions>
                          </v-form>
                        </validation-observer>
                      </v-tab-item>

                      <v-tab-item key="en">
                        <validation-observer ref="formEn" v-slot="{ handleSubmit, failed }">
                          <v-form lazy-validation @submit.prevent="handleSubmit(selectActiveTab)">
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider rules="required|max:200" v-slot="{ errors }" name="title">
                                      <v-text-field
                                        v-model="editedItem.title_en"
                                        name="name"
                                        clearable
                                        counter="200"
                                        :label="$t('articlesPage.title')"
                                        :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                  </v-col>

                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider rules="required|max:200" v-slot="{ errors }" name="intro_text">
                                      <v-text-field
                                        v-model="editedItem.intro_text_en"
                                        name="intro_text"
                                        clearable
                                        counter="200"
                                        :label="$t('articlesPage.intro_text')"
                                        :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider rules="image|size:4000" v-slot="{ errors }" name="image">
                                      <v-file-input
                                        v-model="editedItem.picture"
                                        accept="image/jpg, image/jpeg, image/png"
                                        counter
                                        :label="$t('articlesPage.image')"
                                        prepend-icon="mdi-camera"
                                        show-size
                                        small-chips
                                        :error-messages="errors"></v-file-input>
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="12" md="12" sm="12">
                                    <v-label>{{ $t('articlesPage.body') }}</v-label>
                                    <validation-provider rules="required" v-slot="errors" name="body">
                                      <vue-editor
                                        v-model="editedItem.body_en"
                                        :placeholder="$t('articlesPage.body')"></vue-editor>
                                      <div v-if="errors.errors[0]" v class="v-text-field__details">
                                        <div class="v-messages theme--light error--text" role="alert">
                                          <div class="v-messages__wrapper">
                                            <div class="v-messages__message">
                                              {{ errors.errors[0] }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </validation-provider>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <button-submit
                                :failed="failed"
                                :loading="loading"
                                buttonText="buttons.next"></button-submit>
                            </v-card-actions>
                          </v-form>
                        </validation-observer>
                      </v-tab-item>

                      <v-tab-item key="ru">
                        <validation-observer ref="formRu" v-slot="{ handleSubmit, failed }">
                          <v-form lazy-validation @submit.prevent="handleSubmit(selectActiveTab)">
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider rules="required|max:200" v-slot="{ errors }" name="title">
                                      <v-text-field
                                        v-model="editedItem.title_ru"
                                        name="name"
                                        clearable
                                        counter="200"
                                        :label="$t('articlesPage.title')"
                                        :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                  </v-col>

                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider rules="required|max:200" v-slot="{ errors }" name="intro_text">
                                      <v-text-field
                                        v-model="editedItem.intro_text_ru"
                                        name="intro_text"
                                        clearable
                                        counter="200"
                                        :label="$t('articlesPage.intro_text')"
                                        :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="12" md="12" sm="12">
                                    <validation-provider rules="image|size:4000" v-slot="{ errors }" name="image">
                                      <v-file-input
                                        v-model="editedItem.picture"
                                        accept="image/jpg, image/jpeg, image/png"
                                        counter
                                        :label="$t('articlesPage.image')"
                                        prepend-icon="mdi-camera"
                                        show-size
                                        small-chips
                                        :error-messages="errors"></v-file-input>
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="12" md="12" sm="12">
                                    <v-label>{{ $t('articlesPage.body') }}</v-label>
                                    <validation-provider rules="required" v-slot="errors" name="body">
                                      <vue-editor
                                        v-model="editedItem.body_ru"
                                        :placeholder="$t('articlesPage.body')"></vue-editor>
                                      <div v-if="errors.errors[0]" v class="v-text-field__details">
                                        <div class="v-messages theme--light error--text" role="alert">
                                          <div class="v-messages__wrapper">
                                            <div class="v-messages__message">
                                              {{ errors.errors[0] }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </validation-provider>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <button-submit
                                :failed="failed"
                                :loading="loading"
                                buttonText="buttons.save"></button-submit>
                            </v-card-actions>
                          </v-form>
                        </validation-observer>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.actions') }}
                    </span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="editItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.edit') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="showProfilePicture(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-fit-to-screen</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.showImage') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.showImage') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="showArticleModal(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.delete') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.delete') }}</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-snackbar v-model="snackbar.active" :color="snackbar.color" :timeout="2000" bottom>
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar.active = false">
            {{ $t('snackbar.close') }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>

    <v-dialog v-model="deleteArticleModal" max-width="650">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ $t('articlesPage.deleteArticle') }}
          <v-spacer></v-spacer>
          <v-icon class="mdi mdi-close" style="color: white" @click="closeDeleteArticleModal"></v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <p>
              {{ $t('articlesPage.deleteMessage') }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" text @click="deleteArticle">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogImage" height="auto" width="auto">
      <v-card>
        <v-img
          v-if="itemImage"
          :src="itemImage"
          max-height="600"
          max-width="600"
          style="background-size: cover"></v-img>
        <v-img
          v-else
          aspect-ratio="2"
          contain
          height="250px"
          src="@/assets/default-user.png"
          style="background-size: cover"
          width="250px"></v-img>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { articlesHeaders } from '@/mixins/data-table-headers';
import { defaultArticle } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { formatDate } from '@/utils/formatDate';
import { VueEditor, Quill } from 'vue2-editor';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-vue';
import { DatePicker } from 'v-calendar/src/components';

Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);

export default {
  name: 'articlesPage',
  components: { ButtonSubmit, VueEditor, DatePicker },
  data: () => ({
    searchString: '',
    dialog: false,
    dialogDelete: false,
    articles: [],
    formTitle: 'articlesPage.newItem',
    formValid: true,
    editedItem: {},
    editedItemDeleted: {},
    enableSave: true,
    publishDate: null,
    deleteArticleModal: false,
    statusSuccessfullyChanged: false,
    loading: false,
    snackbar: {
      active: false,
      color: '',
      text: '',
    },
    dialogImage: false,
    itemImage: null,
    activeTab: null,
    modelConfig: {
      type: 'string',
      mask: 'DD-MM-YYYY HH:mm'
    },
  }),
  created() {
    this.editedItem = Object.assign({}, defaultArticle);
    this.loadAllArticles();
  },
  computed: {
    articlesHeaders() {
      return articlesHeaders(i18n);
    },
  },
  methods: {
    async loadAllArticles() {
      await this.$store.dispatch('articles/getAllArticles').then((res) => {
        this.articles = res.data.map((item) => {
          return { ...item, created_at: formatDate(item.created_at) };
        });
      });
    },

    editItem(item) {
      this.editedItem = this.articles.indexOf(item);

      this.editedItem = Object.assign(
        {},
        {
          ...item,
          title: JSON.parse(item.title).me,
          title_en: JSON.parse(item.title).en,
          title_ru: JSON.parse(item.title).ru,
          intro_text: JSON.parse(item.intro_text).me,
          intro_text_en: JSON.parse(item.intro_text).en,
          intro_text_ru: JSON.parse(item.intro_text).ru,
          body: JSON.parse(item.body).me,
          body_en: JSON.parse(item.body).en,
          body_ru: JSON.parse(item.body).ru,
        }
      );
      this.formTitle = JSON.parse(item.title).me;
      this.dialog = true;
    },

    async deleteArticle() {
      this.loading = true;

      await this.$store
        .dispatch('articles/deleteArticle', this.editedItemDeleted)
        .then(() => {
          this.loadAllArticles();
          this.showSnackbar(i18n.t('snackbar.deletedSuccessfully'), 'green');
          this.closeDeleteArticleModal();
        })
        .catch(() => {
          this.showSnackbar(i18n.t('snackbar.anErrorOccured'), 'red');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    createFormData() {
      let formData = new FormData();
      if (this.editedItem.id) {
        formData.append('_method', 'POST');
        formData.append('id', this.editedItem.id);
      } else {
        formData.append('id', '');
      }
      formData.append(
        'title',
        JSON.stringify({
          me: this.editedItem.title,
          en: this.editedItem.title_en,
          ru: this.editedItem.title_ru,
        })
      );
      formData.append(
        'body',
        JSON.stringify({
          me: this.editedItem.body,
          en: this.editedItem.body_en,
          ru: this.editedItem.body_ru,
        })
      );
      formData.append(
        'intro_text',
        JSON.stringify({
          me: this.editedItem.intro_text,
          en: this.editedItem.intro_text_en,
          ru: this.editedItem.intro_text_ru,
        })
      );
      formData.append('picture', this.editedItem.picture ? this.editedItem.picture : '');
      formData.append('publish_date', this.editedItem.publish_date);
      return formData;
    },

    async selectActiveTab(tab = null) {
      let previousTab = this.activeTab;
      const isValid = await this.validateCurrentTab();
      if (isValid) {
        if (tab !== null) {
          if (previousTab == 0) {
            this.translateDescription(this.editedItem.body);
          }
          this.activeTab = tab; // Change to the selected tab if current is valid
        } else {
          if (previousTab < 2) {
            if (previousTab == 0) {
              this.translateDescription(this.editedItem.body);
            }
            this.activeTab += 1; // Move to the next tab if current tab is validated
          } else {
            this.save(); // Save if the last tab is validated
          }
        }
      } else {
        this.activeTab = previousTab;
      }
    },

    async validateCurrentTab() {
      switch (this.activeTab) {
        case 0:
          return await this.$refs.formMe.validate();
        case 1:
          return await this.$refs.formEn.validate();
        case 2:
          return await this.$refs.formRu.validate();
        default:
          return false;
      }
    },

    async save() {
      this.enableSave = false;
      this.loading = true;
      let path = 'saveArticle';

      if (this.editedItem.id) {
        path = 'updateArticle';
      }
      let formData = this.createFormData();

      await this.$store
        .dispatch('articles/' + path, formData)
        .then(() => {
          this.loadAllArticles();
          this.showSnackbar(i18n.t('snackbar.savedSuccessfully'), 'green');

          this.close();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.showSnackbar(e, 'red'));
          } else {
            this.showSnackbar(i18n.t('snackbar.anErrorOccured'), 'red');
          }
        })
        .finally(() => {
          this.enableSave = true;
          this.loading = false;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultArticle);
      });
      this.$refs.formMe && this.$refs.formMe.reset();
      this.$refs.formEn && this.$refs.formEn.reset();
      this.$refs.formRu && this.$refs.formRu.reset();
      this.formTitle = 'articlesPage.newItem';
      this.activeTab = 'me';
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultArticle);
      });
    },

    showSnackbar(text, color) {
      this.snackbar.active = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
    },

    showArticleModal(item) {
      this.editedItemDeleted = item;
      this.deleteArticleModal = true;
    },

    showProfilePicture(item) {
      this.dialogImage = true;
      if (item?.picture_path) {
        this.itemImage = item?.picture_path;
      } else {
        this.itemImage = null;
      }
    },

    updatePublishDate() {
      this.$refs.publishDate.save(this.editedItem.publish_date);
    },

    closeDeleteArticleModal() {
      this.deleteArticleModal = false;
    },

    async translateTitle(text) {
      this.editedItem.title_en = await this.translateText(text, 'en', 'sr');
      this.editedItem.title_ru = await this.translateText(text, 'ru', 'sr');
    },

    async translateIntroText(text) {
      this.editedItem.intro_text_en = await this.translateText(text, 'en', 'sr');
      this.editedItem.intro_text_ru = await this.translateText(text, 'ru', 'sr');
    },

    async translateDescription(text) {
      this.editedItem.body_en = await this.translateText(text, 'en', 'sr');
      this.editedItem.body_ru = await this.translateText(text, 'ru', 'sr');
    },

    async translateText(query, targetLang, sourceLang) {
      var data = {
        q: query,
        target: targetLang,
        source: sourceLang,
      };

      try {
        const response = await this.$store.dispatch('googleTranslate/translateText', data);
        const jsonData = await response.json(); // Assuming the action returns a response compatible with `.json()`
        const translatedText = jsonData.data.translations[0].translatedText;

        return translatedText; // This will be returned to the caller of translateText
      } catch (error) {
        this.showSnackbar(i18n.t('snackbar.anErrorOccuredWithTranslation'), 'red');
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>

<style>
.stats {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.cell-style {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
